// Composables used for managing complex state.

// <!-- API -->
import { ref, computed, reactive, nextTick } from 'vue';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';
import gte from 'lodash-es/gte';
import lte from 'lodash-es/lte';
import clamp from 'just-clamp';
import clone from 'just-clone';
import { toDate, endOfDay, startOfDay, addDays, subDays } from 'date-fns';
import { DateTimeISO, DateTimeLocal } from '@/utils/datetime';
import {
    DateRange,
    DateRangeFilter,
    DateRangeModifier,
    LocationFilter,
    WeatherStationFilter,
} from '@/utils/filters';
import { DropdownOption } from '@/utils/options';
import {
    getDateRangeTotal,
    getDateRangeOverlap,
} from '@/features/analysis/utils';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';
import { Node, NodeRecord, NodeSelector, NodeState } from '@/utils/tree';
import { useDebounceFn, watchDebounced } from '@vueuse/core';
import is from '@sindresorhus/is';
import compare from 'just-compare';
/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */

/** @typedef {import('@formkit/core').FormKitConfig} FormKitConfig */
/** @typedef {import('@formkit/core').FormKitProps} FormKitProps */
/** @typedef {import('@formkit/core').FormKitNode} FormKitNode */

/** @typedef {import('@/utils/options').IOption} IOption */
/** @typedef {import('@/utils/options').IDropdownOption} IDropdownOption */
/** @typedef {import('@/utils/options').IOptionSelected} IOptionSelected */

/** @typedef {import('@/utils/date').Day} Day The day of the week type alias (0 | 1 | 2 | 3 | 4 | 5 | 6), from Sunday to Saturday. */
/** @typedef {import('@/utils/date').IDate} IDate A native {@link Date} instance or JavaScript {@link ITimestamp}. */
/** @typedef {import('@/utils/date').IInterval} IInterval An object that combines two dates to represent the time interval. */
/** @typedef {import('@/utils/date').IDuration} IDuration Duration object. All values are initialized to `0` by default. */
/** @typedef {import('@/utils/date').ITimestamp} ITimestamp Instant in datetime specifing time (in milliseconds) since the JavaScript epoch. */
/** @typedef {import('@/utils/date').IUnixTimestamp} IUnixTimestamp Instant in datetime specifing time (in seconds) since the Unix epoch. */

/** @typedef {import('@/utils/filters').IDateRangeFilter} IDateRangeFilter */

/** @typedef {import('@/utils/filters').ILocation} ILocation */

/** @typedef {ReturnType<useState>} IDateRangeSidebarFilterState Local state. */
/** @typedef {ReturnType<useConstants>} IDateRangeSidebarFilterConstants Local constants. */
/** @typedef {ReturnType<useProperties>} IDateRangeSidebarFilterProperties Local computed properties. */
/** @typedef {ReturnType<useMethods>} IDateRangeSidebarFilterMethods Local component methods. */
/** @typedef {ReturnType<useWatchers>} IDateRangeSidebarFilterWatchers Local component watchers. */

/**
 * @typedef {Object} IDateRangeSidebarFilter
 * @property {Store<ECNBState>} store
 * @property {IDateRangeSidebarFilterState} state
 * @property {IDateRangeSidebarFilterConstants} constants
 * @property {IDateRangeSidebarFilterProperties} properties
 * @property {IDateRangeSidebarFilterMethods} methods
 * @property {IDateRangeSidebarFilterWatchers} watchers
 */

/**
 * @typedef {Object} IDateFormData
 * @property {IDate} start The start of the interval.
 * @property {IDate} end The end of the interval.
 * @property {Boolean} all The all modifier checked state.
 * @property {Boolean} overlap The overlap modifier checked state.
 */

// <!-- HELPERS -->

/**
 * Date range filter form data.
 * @implements {IDateFormData}
 */
class DateFormData {
    /**
     * Create a new instance, using optional explicit parameters.
     * @param {Readonly<Partial<IInterval>>} [range]
     * @param {Iterable<'all' | 'overlap'>} [checked]
     * @returns {IDateFormData}
     */
    static create = (range = {}, checked = []) => {
        const { start = NaN, end = NaN } = range;
        const modifiers = new Set(checked ?? []);
        const instance = new DateFormData({
            start,
            end,
            all: modifiers.has('all'),
            overlap: modifiers.has('overlap'),
        });
        return instance;
    };

    /**
     * Clone an existing instance.
     * @param {Readonly<IDateFormData>} source
     * @returns {DateFormData}
     */
    static clone = (source) => new DateFormData(source);

    /**
     * Get the enabled modifiers from an object of modifier toggle flags.
     * @param {Readonly<Partial<Pick<IDateFormData, 'all' | 'overlap'>>>} [source]
     * @returns {Set<'all' | 'overlap'>}
     */
    static getEnabledModifiersFromInstance = (source = {}) => {
        if (!isNil(source)) {
            /** @type {Array<'all' | 'overlap'>} */
            const ids = ['all', 'overlap'];
            const checked = ids.filter((id) => source?.[id] === true);
            return new Set(checked);
        }
        // Return an empty set if no input is provided.
        return new Set([]);
    };

    /**
     * Get the formatted date from an input {@link IDate} instance.
     * @param {Readonly<IDate>} date
     * @returns {String}
     */
    static getFormattedDateString = (date) => {
        if (!isNil(date)) {
            const value = Number(date.valueOf());
            const isValueNaN = Number.isNaN(value);
            const isValueFinite = Number.isFinite(value);
            const isValueFormattable = !isValueNaN && isValueFinite;
            if (isValueFormattable) {
                const { MIN_SAFE_TIME, MAX_SAFE_TIME } = DateRange;
                const isGTEMin = gte(value, MIN_SAFE_TIME);
                const isLTEMax = lte(value, MAX_SAFE_TIME);
                const isValueWithinBounds = isGTEMin && isLTEMax;
                if (isValueWithinBounds) {
                    // Base case:
                    // Format date as `[yy]yyyy-MM-ddThh:mm:ss.sss`.
                    const dateString = DateTimeLocal.format(value);
                    // Split on the `T` delimiter to get the date component.
                    const [formatted] = dateString.split('T');
                    // Return the date component.
                    return formatted;
                } else {
                    // Recursive case:
                    // If out of bounds, clamp between safe times.
                    const safe = clamp(MIN_SAFE_TIME, value, MAX_SAFE_TIME);
                    // Get the formatted safe date.
                    return DateFormData.getFormattedDateString(safe);
                }
            }
        }
        // Base case:
        // Return empty string if no input is provided.
        // OR if input is NaN
        // OR if input is not finite (eg. Infinity, -Infinity)
        return '';
    };

    /**
     * Construct a form data instance.
     * @param {Readonly<Partial<IDateFormData>>} props
     */
    constructor(props = {}) {
        // <!-- DESTRUCTURE -->
        const {
            start = NaN,
            end = NaN,
            all = false,
            overlap = false,
        } = props ?? {};

        /** @type {IDate} A {@link ITimestamp} or {@link Date} instance respresenting the start of the date interval. */
        this.start = start;

        /** @type {IDate} A {@link ITimestamp} or {@link Date} instance respresenting the end of the date interval. */
        this.end = end;

        /** @type {Boolean} Is the `all` date range modifier switch enabled? */
        this.all = all === true;

        /** @type {Boolean} Is the `overlap` date range modifier switch enabled? */
        this.overlap = overlap === true;
    }

    /**
     * Calculate the formatted start date.
     * @returns {String}
     */
    formatStartDate() {
        return DateFormData.getFormattedDateString(this.start);
    }

    /**
     * Calculate the formatted start date.
     * @returns {String}
     */
    formatEndDate() {
        return DateFormData.getFormattedDateString(this.end);
    }

    /**
     * Get iterable of checked modifiers.
     * @returns {Set<'all' | 'overlap'>}
     */
    checked() {
        return DateFormData.getEnabledModifiersFromInstance(this);
    }
}

/** Define the reactive state. */
const useState = () => {
    // <!-- DEFINE -->
    /** @type {V.Ref<('loading')[]>} Status used to track when the filter is busy calculating. */
    const status = ref([]);
    /** @type {V.Ref<IDropdownOption[]>} Preset dropdown options. */
    const options = ref([]);
    /** @type {V.Ref<Readonly<IDateFormData>>} Snapshot of the store data. Used to determine if an input is dirty. */
    const cleanData = ref(DateFormData.create());
    /** @type {V.Ref<IDateFormData>} Dirty form data, compatible with FormKit inputs, used with `v-model`. */
    const dirtyData = ref(DateFormData.clone(cleanData.value));
    /** @type {V.Ref<String>} Form ID. */
    const formID = ref('form-filter-daterange');
    /** @type {V.Ref<Partial<FormKitProps> & Partial<FormKitConfig>>} */
    const config = ref({});
    /** @type {Map<String, FormKitNode>} Nodes. Useful for tracking validity. */
    const nodes = reactive(new Map([]));
    // <!-- EXPOSE -->
    return {
        status,
        options,
        cleanData,
        dirtyData,
        formID,
        config,
        nodes,
    };
};

/** Define constants for the composable. */
const useConstants = () => {
    // <!-- DEFINE -->
    /** @typedef {Partial<FormKitProps> & Partial<FormKitConfig>} */
    const DefaultFormKitConfig = {
        delay: 300,
        validationVisibility: 'dirty',
    };
    /** Preset range details. */
    const PresetRange = /** @type {const} */ ({
        '1D': {
            value: '1D',
            label: '1 Day',
            duration: { days: 1 },
        },
        '5D': {
            value: '5D',
            label: '5 Days',
            duration: { days: 5 },
        },
        '1W': {
            value: '1W',
            label: '1 Week',
            duration: { weeks: 1 },
        },
        '1M': {
            value: '1M',
            label: '1 Month',
            duration: { months: 1 },
        },
        '3M': {
            value: '3M',
            label: '3 Months',
            duration: { months: 3 },
        },
        '6M': {
            value: '6M',
            label: '6 Months',
            duration: { months: 6 },
        },
        '1Y': {
            value: '1Y',
            label: '1 Year',
            duration: { years: 1 },
        },
        '3Y': {
            value: '3Y',
            label: '3 Years',
            duration: { years: 3 },
        },
    });
    /** @type {{ label: String, value: keyof PresetRange }[]} */
    const PresetOptions = Object.keys(PresetRange).map(
        /**
         * Map keys into preset range option entries.
         * @param {keyof PresetRange} key
         * @returns {{ label: String, value: keyof PresetRange }}
         */
        (key) => {
            const value = key;
            const label = PresetRange[key].label;
            return { label, value };
        }
    );
    /** Date range filter tooltips. */
    const DateRangeFilterTooltips = /** @type {const} */ ({
        all: 'Date range includes all data points across selected Locations.',
        overlap:
            'Date range only includes data points from overlap between selected Locations.',
    });
    // <!-- EXPOSE -->
    return {
        DefaultFormKitConfig,
        PresetRange,
        PresetOptions,
        DateRangeFilterTooltips,
    };
};

/**
 * Define computed properties.
 * @param {Pick<IDateRangeSidebarFilter, 'store' | 'state' | 'constants'>} context
 */
const useProperties = (context) => {
    // <!-- DESTRUCTURE -->
    const { state } = context;
    // <!-- DEFINE -->
    /** @type {V.ComputedRef<Boolean>} Is the filter calculating? */
    const isLoading = computed(() => {
        const status = new Set(state.status.value);
        const hasLoadingStatus = status.has('loading');
        return hasLoadingStatus;
    });
    /** @type {V.ComputedRef<Boolean>} Is the preset dropdown empty? */
    const isDropdownEmpty = computed(() => {
        const options = [...state.options.value];
        const isEmpty = options.length === 0;
        return isEmpty;
    });
    /** @type {V.ComputedRef<Boolean>} Is the modifier enabled? */
    const isAllModifierEnabled = computed(() => {
        const formData = DateFormData.clone(state.dirtyData.value);
        const modifiers = formData.checked();
        const isChecked = modifiers.has('all');
        return isChecked;
    });
    /** @type {V.ComputedRef<Boolean>} Is the modifier enabled? */
    const isOverlapModifierEnabled = computed(() => {
        const formData = DateFormData.clone(state.dirtyData.value);
        const modifiers = formData.checked();
        const isChecked = modifiers.has('overlap');
        return isChecked;
    });
    /** @type {V.ComputedRef<Map<String, Boolean>>} Is the form considered valid? */
    const isFormInputValid = computed(() => {
        const entries = Array.from(state.nodes.entries());
        return new Map(
            entries.map(([name, node]) => {
                const exists = !!node;
                return [name, exists ? node.context.state.valid : false];
            })
        );
    });
    /** @type {V.ComputedRef<Boolean>} Is the date-local inputs disabled? */
    const isDateInputDisabled = computed(() => {
        const hasLoadingStatus = isLoading.value;
        const formData = DateFormData.clone(state.dirtyData.value);
        const modifiers = formData.checked();
        const isAllChecked = modifiers.has('all');
        const isOverlapChecked = modifiers.has('overlap');
        const isModifierEnabled = isAllChecked || isOverlapChecked;
        const isDisabled = hasLoadingStatus || isModifierEnabled;
        return isDisabled;
    });
    /** @type {V.ComputedRef<String>} Get the formatted clean start date. */
    const formattedCleanStartDate = computed(() => {
        const formData = DateFormData.clone(state.cleanData.value);
        const formatted = formData.formatStartDate();
        return formatted;
    });
    /** @type {V.ComputedRef<String>} Get the formatted clean start date. */
    const formattedCleanEndDate = computed(() => {
        const formData = DateFormData.clone(state.cleanData.value);
        const formatted = formData.formatEndDate();
        return formatted;
    });
    // <!-- EXPOSE -->
    return {
        isLoading,
        isDropdownEmpty,
        isFormInputValid,
        isDateInputDisabled,
        isAllModifierEnabled,
        isOverlapModifierEnabled,
        formattedCleanStartDate,
        formattedCleanEndDate,
    };
};

/**
 * Define interface for the composable funtionality.
 * @param {Pick<IDateRangeSidebarFilter, 'store' | 'state' | 'constants' | 'properties'>} context
 */
const useMethods = (context) => {
    // <!-- DESTRUCTURE -->
    const { store, state, constants, properties } = context;
    // <!-- DEFINE -->
    /** Define getters for extracting composable state details. */
    const useGetters = () => {
        // <!-- DEFINE -->
        /**
         * Get the last snapshot of the date range.
         * @returns {IInterval}
         */
        const getCleanDateInterval = () => {
            const range = DateRange.clone(state.cleanData.value);
            return range;
        };
        /**
         * Get the last snapshot of the date modifiers.
         * @returns {Set<'all' | 'overlap'>}
         */
        const getCleanDateModifiers = () => {
            const modifiers = DateFormData.getEnabledModifiersFromInstance(
                state.cleanData.value
            );
            return modifiers;
        };
        /**
         * Get the inputs' date range value.
         * @returns {IInterval}
         */
        const getDirtyDateInterval = () => {
            const range = DateRange.clone(state.dirtyData.value);
            return range;
        };
        /**
         * Get the inputs' date modifier values.
         * @returns {Set<'all' | 'overlap'>}
         */
        const getDirtyDateModifiers = () => {
            const modifiers = DateFormData.getEnabledModifiersFromInstance(
                state.dirtyData.value
            );
            return modifiers;
        };
        /**
         * Query the document for the specified input element.
         * @param {`filter-date-start` | `filter-date-end`} id
         * @returns {HTMLInputElement}
         */
        const getDateInputElement = (id) => {
            const isValidID = !isNil(id);
            if (isValidID) {
                const element = /** @type {HTMLInputElement} */ (
                    document.querySelector(`#${id}`)
                );
                return element;
            }
            // If invalid ID, return null.
            return null;
        };
        /**
         * Get the duration for the associated preset range option.
         * @param {keyof constants['PresetRange']} key
         * @returns {IDuration}
         */
        const getPresetRangeDuration = (key) => {
            const entry = constants.PresetRange?.[key];
            return entry?.duration ?? {};
        };
        /**
         * Query the node map for the specified {@link FormKitNode} instance.
         * @param {`start` | `end`} id
         * @returns {FormKitNode}
         */
        const getNodeReference = (id) => {
            const isValidID = !isNil(id);
            if (isValidID && state.nodes.has(id)) {
                const node = state.nodes.get(id);
                return node;
            }
            // If invalid ID, return null.
            return null;
        };
        /**
         * Get node validity.
         * @param {'start' | 'end'} id
         * @returns {Boolean}
         */
        const getNodeValidity = (id) => {
            const node = getNodeReference(id);
            if (!isNil(node)) {
                // Get the node context and its validity.
                const isValid = node.context.state.valid === true;
                return isValid;
            }
            // False, if not found.
            return false;
        };
        // <!-- EXPOSE -->
        return {
            getCleanDateInterval,
            getCleanDateModifiers,
            getDirtyDateInterval,
            getDirtyDateModifiers,
            getDateInputElement,
            getPresetRangeDuration,
            getNodeReference,
            getNodeValidity,
        };
    };
    /** Define setters for mutating composable state details. */
    const useSetters = () => {
        // <!-- DEFINE -->
        /**
         * Set the loading status.
         * @param {Boolean} value
         */
        const setLoadingStatus = (value) => {
            const status = new Set(state.status.value);
            const previous = status.has('loading');
            const next = value === true;
            const isDirty = previous !== next;
            if (isDirty) {
                if (next) {
                    state.status.value = [...status.add('loading')];
                    return;
                } else {
                    status.delete('loading');
                    state.status.value = [...status];
                    return;
                }
            }
            // Do nothing if not dirty.
            return;
        };
        /**
         * Set the FormKit configuration.
         * @param {Readonly<Partial<FormKitProps> & Partial<FormKitConfig>>} value
         */
        const setFormKitConfig = (value) => {
            state.config.value = clone(value);
            return;
        };
        /**
         * Set the preset dropdown options.
         * @param {Iterable<IDropdownOption>} values
         */
        const setDropdownOptions = (values) => {
            const options = [...values];
            state.options.value = options;
            return;
        };
        /**
         * Set the clean data using a source instance.
         * @param {Readonly<IDateFormData>} source
         */
        const setCleanData = (source) => {
            const instance = DateFormData.clone(source);
            state.cleanData.value = instance;
        };
        /**
         * Set the clean data using a source interval.
         * @param {Readonly<IInterval>} source
         */
        const setCleanDateInterval = (source) => {
            const instance = DateFormData.clone(state.cleanData.value);
            instance.start = source.start;
            instance.end = source.end;
            state.cleanData.value = instance;
        };
        /**
         * Set the clean data using a source set of modifiers.
         * @param {Iterable<'all' | 'overlap'>} checked
         */
        const setCleanDateModifiers = (checked) => {
            const instance = DateFormData.clone(state.cleanData.value);
            const modifiers = new Set(checked);
            instance.all = modifiers.has('all');
            instance.overlap = modifiers.has('overlap');
            state.cleanData.value = instance;
        };
        /**
         * Set the dirty data using a source instance.
         * @param {Readonly<IDateFormData>} source
         */
        const setDirtyData = (source) => {
            const instance = DateFormData.clone(source);
            state.dirtyData.value = instance;
        };
        /**
         * Set the dirty data using a source interval.
         * @param {Readonly<IInterval>} source
         */
        const setDirtyDateInterval = (source) => {
            const instance = DateFormData.clone(state.dirtyData.value);
            instance.start = source.start;
            instance.end = source.end;
            state.dirtyData.value = instance;
        };
        /**
         * Set the dirty data using a source set of modifiers.
         * @param {Iterable<'all' | 'overlap'>} checked
         */
        const setDirtyDateModifiers = (checked) => {
            const instance = DateFormData.clone(state.dirtyData.value);
            const modifiers = new Set(checked);
            instance.all = modifiers.has('all');
            instance.overlap = modifiers.has('overlap');
            state.dirtyData.value = instance;
        };
        /**
         * Update the text content of the provided element.
         * @param {HTMLInputElement} element Element to update.
         * @param {IDate} date {@link IDate} instance to format and set.
         */
        const setDateInputContent = (element, date) => {
            const isElementEditable = !isNil(element);
            if (isElementEditable) {
                const formatted = DateFormData.getFormattedDateString(date);
                element.value = formatted;
                return;
            }
            // Do nothing, if element is `null`.
        };
        /**
         * Update the node input using the specified date.
         * @param {FormKitNode} node Node to update.
         * @param {IDate} date {@link IDate} instance to format and set.
         */
        const setNodeInput = async (node, date) => {
            const isNodeEditable = !isNil(node);
            if (isNodeEditable) {
                const formatted = DateFormData.getFormattedDateString(date);
                await node.input(formatted, true);
                return;
            }
            // Do nothing, if element is `null`.
        };
        // <!-- EXPOSE -->
        return {
            setLoadingStatus,
            setFormKitConfig,
            setDropdownOptions,
            setCleanData,
            setCleanDateInterval,
            setCleanDateModifiers,
            setDirtyData,
            setDirtyDateInterval,
            setDirtyDateModifiers,
            setDateInputContent,
            setNodeInput,
        };
    };
    /** Define API for the composable. */
    const useActions = () => {
        // <!-- DEFINE -->
        /** Define event triggers for the composable. */
        const useTriggers = () => {
            // <!-- DEFINE -->
            /** Notify system that loading is about to start. */
            const notifyLoadingStart = () => setters.setLoadingStatus(true);
            /** Notify system that loading has finished. */
            const notifyLoadingComplete = () => setters.setLoadingStatus(false);
            /** Initalize the composable. */
            const initialize = () => {
                initializeFormKit();
                initializeDropdown();
                resetCleanData();
                resetDirtyData();
            };
            /** Initialize the {@link FormKitConfig} configuration. */
            const initializeFormKit = () => {
                setters.setFormKitConfig(constants.DefaultFormKitConfig);
            };
            /** Initialize the preset dropdown options. */
            const initializeDropdown = () => {
                // <!-- DEFINE -->
                /** @type {(keyof constants['PresetRange'])[]} */
                const allowed = ['1M', '3M', '6M', '1Y', '3Y'];
                const options = [...constants.PresetOptions]
                    .filter((option) => allowed.includes(option.value))
                    .map((option) => {
                        const { label, value } = option;
                        return DropdownOption.create(
                            label,
                            value,
                            handlers.onPresetSelected
                        );
                    });
                // <!-- APPLY -->
                setters.setDropdownOptions(options);
            };
            /** Reset clean data with the last snapshot from the Vuex store. */
            const resetCleanData = () => {
                const range = actions.loadDateInterval();
                const checked = actions.loadDateModifiers();
                const formData = DateFormData.create(range, checked);
                setters.setCleanData(formData);
            };
            /** Reset dirty data with the current clean data values. */
            const resetDirtyData = () => {
                const formData = state.cleanData.value;
                setters.setDirtyData(formData);
            };
            /** Refresh content on the date input using the current dirty data state. */
            const refreshDateInput = () => {
                // Get copy of dirty data.
                const formData = DateFormData.clone(state.cleanData.value);
                // Get elements that should be updated.
                const el = {
                    start: getters.getDateInputElement('filter-date-start'),
                    end: getters.getDateInputElement('filter-date-start'),
                };
                // Use current interval from the dirty data.
                setters.setDateInputContent(el.start, formData.start);
                setters.setNodeInput(
                    getters.getNodeReference('start'),
                    formData.start
                );
                setters.setDateInputContent(el.end, formData.end);
                setters.setNodeInput(
                    getters.getNodeReference('end'),
                    formData.end
                );
            };
            /**
             * Register the {@link FormKitNode} input nodes on the page.
             * @param {FormKitNode} node
             */
            const registerFormKitNode = (node) => {
                if (!isNil(node) && !isNil(node.name)) {
                    // Add node if it is not nil.
                    state.nodes.set(node.name, node);
                }
            };
            /**
             * Calculate the present date range based off of today's date, subtracted the corresponding preset range.
             * @param {keyof constants['PresetRange']} id
             * @returns {IInterval}
             */
            const calculatePresetDateRange = (id) => {
                // <!-- DEFINE -->
                /** Get the preset date range duration. */
                const duration = getters.getPresetRangeDuration(id);
                /** End date will always be end of the current day. */
                const end = endOfDay(Date.now());
                /** Start date will be the start of the day that is a [preset duration] amount behind the end date. */
                const start = startOfDay(DateTimeLocal.subtract(end, duration));
                /** @type {IInterval} The calculated date range interval. */
                const range = {
                    start: start.valueOf(),
                    end: end.valueOf(),
                };
                // <!-- EXPOSE -->
                return range;
            };
            /**
             * Calculate the total interval range from the specified list of location resources.
             * @param {Array<Pick<ILocation, 'minDate' | 'maxDate'>>} resources
             * @returns {IInterval}
             */
            const calculateTotalInterval = (resources) => {
                if (!isNil(resources) && resources.length > 0) {
                    /** @type {[ start: IDate, end: IDate ][]} */
                    const dates = resources.map(({ minDate, maxDate }) => {
                        const isMinDateEmpty = isNil(minDate) || minDate === '';
                        const isMaxDateEmpty = isNil(maxDate) || maxDate === '';
                        const start = isMinDateEmpty
                            ? NaN
                            : startOfDay(DateTimeISO.parse(minDate));
                        const end = isMaxDateEmpty
                            ? NaN
                            : endOfDay(DateTimeISO.parse(maxDate));
                        /** @type {[ start: IDate, end: IDate ]} */
                        const interval = [start, end];
                        return interval;
                    });
                    const calculation = getDateRangeTotal(dates);
                    return calculation;
                }
                return { start: NaN, end: NaN };
            };
            /**
             * Calculate the smallest common overlapping interval range from the specified list of location resources.
             * @param {Array<Pick<ILocation, 'minDate' | 'maxDate'>>} resources
             * @returns {IInterval}
             */
            const calculateOverlapInterval = (resources) => {
                if (!isNil(resources) && resources.length > 0) {
                    /** @type {[ start: IDate, end: IDate ][]} */
                    const dates = resources.map(({ minDate, maxDate }) => {
                        const isMinDateEmpty = isNil(minDate) || minDate === '';
                        const isMaxDateEmpty = isNil(maxDate) || maxDate === '';
                        const start = isMinDateEmpty
                            ? NaN
                            : startOfDay(DateTimeISO.parse(minDate));
                        const end = isMaxDateEmpty
                            ? NaN
                            : endOfDay(DateTimeISO.parse(maxDate));
                        /** @type {[ start: IDate, end: IDate ]} */
                        const interval = [start, end];
                        return interval;
                    });
                    const calculation = getDateRangeOverlap(dates);
                    return calculation;
                }
                return { start: NaN, end: NaN };
            };
            /**
             * Blur the menu dropdown element.
             * @param {'dropdown-preset'} id
             */
            const blurPresetMenuDropdown = (id) => {
                if (id) {
                    // <!-- BLUR DROPDOWN -->
                    const element = document?.getElementById(id);
                    element?.blur();
                }
            };
            // <!-- EXPOSE -->
            return {
                notifyLoadingStart,
                notifyLoadingComplete,
                initialize,
                initializeFormKit,
                initializeDropdown,
                registerFormKitNode,
                resetCleanData,
                resetDirtyData,
                refreshDateInput,
                calculatePresetDateRange,
                calculateTotalInterval,
                calculateOverlapInterval,
                blurPresetMenuDropdown,
            };
        };
        /** Define Vuex store deserializers. */
        const useDeserializers = () => {
            // <!-- DEFINE -->
            /**
             * Deserialize the date range filter from the store.
             * @returns {IDateRangeFilter}
             */
            const loadDateRangeFilter = () => {
                const filter = DateRangeFilter.clone(
                    store.state.analysis.filters.dates
                );
                return filter;
            };
            /**
             * Deserialize the date range from the store.
             * @returns {IInterval}
             */
            const loadDateInterval = () => {
                const filter = actions.loadDateRangeFilter();
                const range = DateRange.clone(filter);
                return range;
            };
            /**
             * Deserialize the date range modifiers from the store.
             * @returns {Array<'all' | 'overlap'>}
             */
            const loadDateModifiers = () => {
                const filter = actions.loadDateRangeFilter();
                const { checked } = DateRangeModifier.clone(filter);
                return checked;
            };
            // <!-- EXPOSE -->
            return {
                loadDateRangeFilter,
                loadDateInterval,
                loadDateModifiers,
            };
        };
        /** Define Vuex store serializers. */
        const useSerializers = () => {
            // <!-- DEFINE -->
            /**
             * Save interval to the Vuex store.
             * @param {IInterval} interval
             */
            const saveDateInterval = async (interval) => {
                try {
                    const next = DateRange.clone(interval);
                    await store.dispatch('analysis/assignDateRange', next);
                } catch (e) {
                    console.warn(e);
                }
            };
            /**
             * Save modifiers to the Vuex store.
             * @param {Iterable<'all' | 'overlap'>} checked
             */
            const saveDateModifiers = async (checked) => {
                try {
                    // Clear the existing date range modifiers.
                    store.commit('analysis/clearDateRangeModifiers');
                    // Assign the new set of modifiers.
                    const modifiers = new Set(checked);
                    await store.dispatch('analysis/assignDateRangeModifiers', [
                        { key: 'all', value: modifiers.has('all') },
                        { key: 'overlap', value: modifiers.has('overlap') },
                    ]);
                } catch (e) {
                    console.warn(e);
                }
            };
            // <!-- EXPOSE -->
            return {
                saveDateInterval,
                saveDateModifiers,
            };
        };
        // <!-- EXPOSE -->
        const triggers = useTriggers();
        const serializers = useSerializers();
        const deserializers = useDeserializers();
        return {
            ...triggers,
            ...serializers,
            ...deserializers,
        };
    };
    /** Define event handlers. */
    const useEventHandlers = () => {
        // <!-- DEFINE -->
        /**
         * Invoked when a preset option is selected in the dropdown.
         * @param {Readonly<Pick<IDropdownOption, 'label' | 'value'>>} target Invoking option.
         */
        const onPresetSelected = async (target) => {
            const isNotLoading = properties.isLoading.value !== true;
            if (!isNil(target) && !isNil(target.value) && isNotLoading) {
                try {
                    // Start loading.
                    actions.notifyLoadingStart();
                    // <!-- BLUR -->
                    actions.blurPresetMenuDropdown('dropdown-preset');
                    // <!-- DESTRUCTURE -->
                    const { label, value } = target;
                    console.log(`[select::preset] =>`, { label, value }); // debug log.
                    // <!-- APPLY -->
                    // Wait until the modifiers have been disabled.
                    await actions.saveDateModifiers([]);
                    // Calculate the range.
                    const key = /** @type {keyof constants['PresetRange']} */ (
                        value
                    );
                    const range = actions.calculatePresetDateRange(key);
                    // Wait until the calculated range is saved to the store.
                    await actions.saveDateInterval(range);
                    // Return once completed.
                    return;
                } catch (e) {
                    console.warn(e);
                } finally {
                    // Stop loading.
                    actions.notifyLoadingComplete();
                }
            }
            // <!-- ERROR -->
            throw new TypeError(`Invalid PresetChange event.`);
        };
        /**
         * Invoked after the `analysis/assignDateRange` action is dispatched.
         * @param {Readonly<IInterval>} interval The assigned interval.
         */
        const onAssignDateRange = async (interval) => {
            const previous = DateRange.clone(state.cleanData.value);
            const next = DateRange.clone(interval);
            const isDirty =
                previous.start.valueOf() !== next.start.valueOf() ||
                previous.end.valueOf() !== next.end.valueOf();
            if (isDirty) {
                // Update the clean, dirty, and input values to reflect assigned date range.
                return await handlers.onRefreshDateInputs();
            }
        };
        /**
         * Invoked after the `analysis/assignDateRange` action is dispatched.
         * @param {Readonly<Iterable<{ key: 'all' | 'overlap', value: Boolean }>>} checked The assigned modifiers.
         * @param {Boolean} [forceRefresh]
         */
        const onAssignDateRangeModifiers = async (
            checked,
            forceRefresh = false
        ) => {
            try {
                actions.notifyLoadingStart();
                const previous = DateFormData.getEnabledModifiersFromInstance(
                    state.cleanData.value
                );
                const next = [...checked].reduce(
                    /**
                     * Reduce checked modifier instructions into a set of checked modifiers.
                     * @param {Set<'all' | 'overlap'>} previous
                     * @param {{ key: 'all' | 'overlap', value: Boolean }} current
                     */
                    (previous, current) => {
                        const set = new Set(previous);
                        const { key, value = false } = current;
                        const isModifierEnabled = !!key && value === true;
                        return isModifierEnabled ? set.add(key) : set;
                    },
                    new Set()
                );
                const isDirty =
                    forceRefresh === true ||
                    previous.has('all') !== next.has('all') ||
                    previous.has('overlap') !== next.has('overlap');
                if (isDirty) {
                    // <!-- HELPERS -->
                    /** @type {(id: String) => Boolean} Is the ID a non-empty string? */
                    const isNotEmptyID = (id) => !isNil(id);
                    /** @type {(id: String) => Boolean} Is the ID parseable to a decimal integer (after removing 'l'). */
                    const isParseableID = (id) =>
                        !Number.isNaN(parseInt(id.substring(1), 10));
                    /** @type {(id: String) => Boolean} Is the ID a valid location ID. */
                    const isValidLocationID = (id) =>
                        isNotEmptyID(id) &&
                        id.startsWith('l') &&
                        isParseableID(id);
                    /** @type {(id: String) => ILocation} Convert id to a resource. */
                    const toLocationResource = (id) =>
                        index.get(parseInt(id.substring(1), 10));

                    // <!-- TRANSFORMS -->
                    // Get the resources from the store.
                    const index = store.state.cache.locations.index;

                    const tree = store.state.analysis.filters.locations.tree;
                    const resources = NodeRecord.where(tree.nodes, (n) => {
                        return (
                            Node.isLocationNode(n) &&
                            NodeState.isChecked(n.state)
                        );
                    }).map((n) => toLocationResource(n.id));

                    // Are one or more locations checked?
                    const isAnyLocationChecked =
                        !isNil(resources) && resources.length > 0;

                    if (isAnyLocationChecked && next.has('all')) {
                        // When `all` is enabled...
                        // Calculate the total range.
                        const range = actions.calculateTotalInterval(resources);
                        // Check if range is valid... Otherwise ignore...
                        if (
                            !Number.isNaN(range.start.valueOf()) &&
                            !Number.isNaN(range.end.valueOf())
                        ) {
                            // Apply the range.
                            return await actions.saveDateInterval(range);
                        }
                    } else if (isAnyLocationChecked && next.has('overlap')) {
                        // When `overlap` is enabled...
                        // Calculate the overlap range.
                        const range =
                            actions.calculateOverlapInterval(resources);
                        // Check if range is valid... Otherwise ignore...
                        if (
                            !Number.isNaN(range.start.valueOf()) &&
                            !Number.isNaN(range.end.valueOf())
                        ) {
                            // Apply the range.
                            return await actions.saveDateInterval(range);
                        }
                    }
                    // When no modifier is enabled...
                    return await handlers.onRefreshDateInputs();
                }
            } catch (e) {
                console.warn(e);
            } finally {
                actions.notifyLoadingComplete();
            }
        };
        /** Invoked when the date input should be refreshed. */
        const onRefreshDateInputs = async () => {
            // Update the clean, dirty, and input values to reflect assigned date range.
            actions.resetCleanData();
            actions.resetDirtyData();
            actions.refreshDateInput();
            await nextTick();
        };
        /**
         * On focus out preset menu dropdown.
         * @param {FocusEvent} event
         */
        const onFocusOutMenuDropdown = (event) => {
            if (event?.relatedTarget === null) {
                // console.log(`[blur::dropdown] => `, event);
                const id = `dropdown-preset`;
                actions.blurPresetMenuDropdown(id);
            }
        };
        /**
         * On date range input, validate the dirty form data.
         * @param {{ id: 'start' | 'end', value: IDate }} event
         */
        const onDateChanged = async (event) => {
            // <!-- DESTRUCTURE -->
            const { id, value } = event ?? {};
            const isValid = !Number.isNaN(value?.valueOf());
            const date = isValid ? new Date(value?.valueOf()) : NaN;
            /** @type {IInterval} */
            const next = DateRange.clone(state.cleanData.value);
            const { MIN_SAFE_TIME, MAX_SAFE_TIME } = DateRange;
            if (id === 'start') {
                if (isValid) {
                    // Clamp start date between safe range.
                    next.start = clamp(
                        MIN_SAFE_TIME,
                        date.valueOf(),
                        MAX_SAFE_TIME
                    );
                    // Clamp end date between start + 1 day and safe max.
                    const MIN_END_DATE = endOfDay(
                        addDays(toDate(next.start), 1)
                    );
                    next.end = clamp(
                        MIN_END_DATE.valueOf(),
                        next.end.valueOf(),
                        MAX_SAFE_TIME
                    );
                } else {
                    // Set start to invalid date. End date remains the same.
                    next.start = NaN;
                }
                // Wait until the changed interval is saved.
                await actions.saveDateInterval(next);
                return;
            }
            if (id === 'end') {
                if (isValid) {
                    // Clamp end date between safe range.
                    next.end = clamp(
                        MIN_SAFE_TIME,
                        endOfDay(date).valueOf(),
                        MAX_SAFE_TIME
                    );
                    // Clamp start date between safe min and end - 1 day.
                    const MAX_START_DATE = startOfDay(
                        subDays(toDate(next.end), 1)
                    );
                    next.start = clamp(
                        MIN_SAFE_TIME,
                        next.start.valueOf(),
                        MAX_START_DATE.valueOf()
                    );
                } else {
                    // Set end to invalid date. Start date remains the same.
                    next.end = NaN;
                }
                // Wait until the changed interval is saved.
                await actions.saveDateInterval(next);
                return;
            }
            // Throw error when unknown id is passed.
            throw new TypeError(`Unknown date (id === '${id}') changed.`);
        };
        /**
         * Handle the `datetime-local`'s `input` event.
         * @param {String} value
         * @param {FormKitNode} node
         */
        const onDateTimeLocalInput = async (value, node) => {
            if (!isNil(node) && !isNil(node.name)) {
                const id = /** @type {'start' | 'end'} */ (node.name);
                const type = 'input';
                const timeStamp = Date.now(); // For logging purposes.
                const isNotEmpty = !isNil(value) && value !== '';
                if (isNotEmpty) {
                    // When the input provides a non-empty date string.
                    const [yearString, monthString, dayString] =
                        value.split('-');
                    const year = parseInt(yearString, 10);
                    const month = parseInt(monthString, 10) - 1; // Note: zero-index based. Jan = 0, Feb = 1, etc....
                    const day = parseInt(dayString, 10);
                    const date = new Date(year, month, day);
                    const isValid = !Number.isNaN(date.valueOf());
                    const isDirty =
                        state.cleanData.value?.[id]?.valueOf() !==
                        date.valueOf();
                    if (isValid && isDirty) {
                        // When casted date is valid, save it.
                        console.log(`[input::${id}] => ${date.valueOf()}`, {
                            value,
                            previous: state.cleanData.value?.[id]?.valueOf(),
                            date,
                            type,
                            timeStamp,
                            node,
                        });
                        // Change the date.
                        await onDateChanged({ id, value: date });
                        return;
                    }
                    // Do nothing when not dirty.
                    return;
                }
                // When the input value is invalid...
                const date = new Date(NaN); // Invalid date.
                const isDirty = !Number.isNaN(
                    state.cleanData.value?.[id]?.valueOf()
                );
                if (isDirty) {
                    console.log(`[input::${id}] => ${date.valueOf()}`, {
                        value,
                        previous: state.cleanData.value?.[id]?.valueOf(),
                        date,
                        type,
                        timeStamp,
                        node,
                    });
                    // Clear the date.
                    await onDateChanged({ id, value: date });
                }
            }
        };
        /**
         * Handle the `datetime-local`'s `click` event.
         * @param {'start' | 'end'} id
         * @param {PointerEvent} event
         */
        const onDateTimeLocalClicked = async (id, event) => {
            if (!isNil(id) && !isNil(event) && !isNil(event.target)) {
                // <!-- DESTRUCTURE -->
                const { type, timeStamp } = event;
                const target = /** @type {HTMLInputElement} */ (event.target);
                const { value } = target;
                // HACK: Important! `target.valueAsDate` is bugged!!!
                //   It parses the datetime local value in the UTC timezone,
                //   but the input expects users to input dates in the local timezone.
                //   This means everyone outside of the UTC timezone will have the WRONG date!
                const isNotEmpty = !isNil(value) && value !== '';
                if (isNotEmpty) {
                    // When the input provides a non-empty date string.
                    const [yearString, monthString, dayString] =
                        value.split('-');
                    const year = parseInt(yearString, 10);
                    const month = parseInt(monthString, 10) - 1; // Note: zero-index based. Jan = 0, Feb = 1, etc....
                    const day = parseInt(dayString, 10);
                    const date = new Date(year, month, day);
                    const isValid = !Number.isNaN(date.valueOf());
                    // When clicking, prevent update when value is invalid date.
                    if (isValid) {
                        // When clicking, prevent update when value is clean.
                        const isDirty =
                            state.cleanData.value?.[id]?.valueOf() !==
                            date.valueOf();
                        if (isDirty) {
                            // When casted date is valid, save it.
                            console.log(`[input::${id}] => ${date.valueOf()}`, {
                                value,
                                previous:
                                    state.cleanData.value?.[id]?.valueOf(),
                                date,
                                type,
                                timeStamp,
                                target,
                            });
                            // Change the date.
                            await onDateChanged({ id, value: date });
                            return;
                        }
                        // Do nothing when not dirty.
                        return;
                    }
                    // Do nothing when not valid.
                    return;
                }
                // When clicking, do not clear the date if invalid.
                return;
            }
        };
        /**
         * Handle the `datetime-local`'s `keyup.enter` event.
         * @param {'start' | 'end'} id
         * @param {KeyboardEvent} event
         */
        const onDateTimeLocalKeyboardEvent = async (id, event) => {
            if (!isNil(id) && !isNil(event) && !isNil(event.target)) {
                const { key, type, timeStamp } = event;
                const target = /** @type {HTMLInputElement} */ (event.target);
                const { value } = target;
                // HACK: Important! `target.valueAsDate` is bugged!!!
                //   It parses the datetime local value in the UTC timezone,
                //   but the input expects users to input dates in the local timezone.
                //   This means everyone outside of the UTC timezone will have the WRONG date!
                // When releasing key, clear value when empty.
                const isNotEmpty = !isNil(value) && value !== '';
                if (isNotEmpty) {
                    // When the input provides a non-empty date string.
                    const [yearString, monthString, dayString] =
                        value.split('-');
                    const year = parseInt(yearString, 10);
                    const month = parseInt(monthString, 10) - 1; // Note: zero-index based. Jan = 0, Feb = 1, etc....
                    const day = parseInt(dayString, 10);
                    const date = new Date(year, month, day);
                    const isValid = !Number.isNaN(date.valueOf());
                    // When releasing key, clear value when invalid.
                    if (isValid) {
                        // When releasing key, prevent update when value is clean.
                        const isDirty =
                            state.cleanData.value?.[id]?.valueOf() !==
                            date.valueOf();
                        if (isDirty) {
                            // When casted date is valid, save it.
                            console.log(`[click::${id}] => ${date.valueOf()}`, {
                                key,
                                previous:
                                    state.cleanData.value?.[id]?.valueOf(),
                                value,
                                date,
                                type,
                                timeStamp,
                                target,
                            });
                            // Change the date.
                            await onDateChanged({ id, value: date });
                            return;
                        }
                        // Do nothing when not dirty.
                        return;
                    }
                    // Fall-through when not valid.
                }
                // When the input value is invalid or empty...
                const date = new Date(NaN); // Invalid date.
                const isDirty = !Number.isNaN(
                    state.cleanData.value?.[id]?.valueOf()
                );
                if (isDirty) {
                    console.log(`[click::${id}] => ${date.valueOf()}`, {
                        key,
                        previous: state.cleanData.value?.[id]?.valueOf(),
                        value,
                        date,
                        type,
                        timeStamp,
                        target,
                    });
                    // Clear the date.
                    await onDateChanged({ id, value: date });
                    return;
                }
            }
        };
        /**
         * Invoked when the switch is toggled.
         * @param {'all' | 'overlap'} id
         * @param {Boolean} value
         */
        const onSwitchToggled = async (id, value) => {
            if (!isNil(id) && !isNil(value)) {
                const key = id === 'all' ? 'all' : 'overlap';
                const type = 'toggle';
                const timeStamp = Date.now(); // For logging purposes.
                const target =
                    document.querySelector(`#filter-date-${key}`) ?? null;
                const previous = DateFormData.getEnabledModifiersFromInstance(
                    state.cleanData.value
                );
                const next = new Set([key].filter((_) => value === true));
                const isDirty =
                    previous.has('all') !== next.has('all') ||
                    previous.has('overlap') !== next.has('overlap');
                if (isDirty) {
                    // Log the event.
                    console.log(`[toggle::${key}] => ${next}`, {
                        key,
                        type,
                        previous,
                        value: next,
                        timeStamp,
                        target,
                    });
                    // Get the clean data.
                    const checked =
                        DateFormData.getEnabledModifiersFromInstance(
                            state.cleanData.value
                        );
                    const isEnabled = next.has(key);
                    if (isEnabled) {
                        // If 'all' or 'overlap' is enabled...
                        // Then, only that modifier should be enabled.
                        await actions.saveDateModifiers([key]);
                        return;
                    } else {
                        // If 'all' is disabled...
                        // Then, any current modifiers should be enabled.
                        const active = [...checked].filter((id) => id !== key);
                        await actions.saveDateModifiers(active);
                        return;
                    }
                }
            }
        };
        // <!-- EXPOSE -->
        return {
            // LISTENERS
            onPresetSelected,
            onAssignDateRange,
            onAssignDateRangeModifiers,
            // UPDATE
            onRefreshDateInputs,
            onFocusOutMenuDropdown,
            onDateChanged,
            // INPUT
            onDateTimeLocalInput,
            onDateTimeLocalClicked,
            onDateTimeLocalKeyboardEvent,
            onSwitchToggled,
        };
    };
    // <!-- EXPOSE -->
    /** @typedef {ReturnType<useGetters>} */
    const getters = useGetters();
    /** @typedef {ReturnType<useSetters>} */
    const setters = useSetters();
    /** @typedef {ReturnType<useActions>} */
    const actions = useActions();
    /** @typedef {ReturnType<useEventHandlers>} */
    const handlers = useEventHandlers();
    return {
        getters,
        setters,
        actions,
        handlers,
    };
};

/**
 * Define interface for the reactive watchers.
 * @param {Pick<IDateRangeSidebarFilter, 'store' | 'state' | 'constants' | 'properties' | 'methods'>} context
 */
const useWatchers = (context) => {
    // DESTRUCTURE dependency sources.
    const { store, state, constants, properties, methods } = context;

    // PREPARE debounced wrapped functions.
    const debounced = {
        onSwitchToggled: useDebounceFn(methods.handlers.onSwitchToggled, 50),
        onAssignDateRange: useDebounceFn(
            methods.handlers.onAssignDateRange,
            200
        ),
        onAssignDateRangeModifiers: useDebounceFn(
            methods.handlers.onAssignDateRangeModifiers,
            100
        ),
    };

    // PREPARE dependency generators.
    const dependencies = {
        dateRange() {
            return DateRange.clone(store.state.analysis.filters.dates);
        },
        modifiers() {
            return DateRangeModifier.clone(store.state.analysis.filters.dates);
        },
        selectedLocations() {
            const filter = LocationFilter.clone(
                store.state.analysis.filters.locations
            );
            return Object.values(filter.tree.nodes)
                .filter(Node.isLocationNode)
                .filter((node) => NodeState.isChecked(node.state))
                .map((node) => String(node.id))
                .map(NodeSelector.readResourceID);
        },
        selectedWeatherStations() {
            const filter = WeatherStationFilter.clone(
                store.state.analysis.filters.stations
            );
            return Object.values(filter.tree.nodes)
                .filter(Node.isWeatherStationNode)
                .filter((node) => NodeState.isChecked(node.state))
                .map((node) => String(node.id))
                .map(NodeSelector.readResourceID);
        },
    };

    /**
     * Define watcher that tracks the all dates modifier toggle.
     * @param {import('@vueuse/core').WatchDebouncedOptions} [options]
     * @returns {import('vue').WatchStopHandle}
     */
    const watchAllDatesToggle = (options = {}) => {
        return watchDebounced(
            [properties.isAllModifierEnabled],
            ([isEnabled], [wasEnabled]) => {
                console.log('[toggle::all]', { isEnabled, wasEnabled });
                debounced.onSwitchToggled('all', isEnabled);
            },
            {
                debounce: 25,
                maxWait: 5000,
                flush: 'pre',
                ...options,
            }
        );
    };

    /**
     * Define watcher that tracks the overlapping dates modifier toggle.
     * @param {import('@vueuse/core').WatchDebouncedOptions} [options]
     * @returns {import('vue').WatchStopHandle}
     */
    const watchOverlappingDatesToggle = (options = {}) => {
        return watchDebounced(
            [properties.isOverlapModifierEnabled],
            ([isEnabled], [wasEnabled]) => {
                console.log('[toggle::overlap]', { isEnabled, wasEnabled });
                debounced.onSwitchToggled('overlap', isEnabled);
            },
            {
                debounce: 25,
                maxWait: 5000,
                flush: 'pre',
                ...options,
            }
        );
    };

    /**
     * Define watcher that tracks date range interval changes.
     * @param {import('@vueuse/core').WatchDebouncedOptions} [options]
     * @returns {import('vue').WatchStopHandle}
     */
    const watchDateRangeInterval = (options = {}) => {
        return watchDebounced(
            dependencies.dateRange,
            (current, previous) => {
                if (!compare(current, previous)) {
                    console.log('[assign::dates::interval]', {
                        current,
                        previous,
                    });
                    debounced.onAssignDateRange(current);
                }
            },
            {
                debounce: 100,
                maxWait: 5000,
                flush: 'pre',
                deep: true,
                immediate: false,
                ...options,
            }
        );
    };

    /**
     * Define watcher that tracks date range modifier changes.
     * @param {import('@vueuse/core').WatchDebouncedOptions} [options]
     * @returns {import('vue').WatchStopHandle}
     */
    const watchDateRangeModifiers = (options = {}) => {
        return watchDebounced(
            dependencies.modifiers,
            (current, previous) => {
                if (!compare(current, previous)) {
                    const checked = ['all', 'overlap'].map((key) => ({
                        key: /** @type {'all' | 'overlap'} */ (key),
                        value: current.checked.includes(
                            /** @type {'all' | 'overlap'} */ (key)
                        ),
                    }));
                    console.log('[assign::dates::modifier]', {
                        current,
                        previous,
                        checked,
                    });
                    debounced.onAssignDateRangeModifiers(checked, false);
                }
            },
            {
                debounce: 0,
                maxWait: 5000,
                flush: 'pre',
                deep: true,
                immediate: false,
                ...options,
            }
        );
    };

    /**
     * Define watcher that tracks location filter changes.
     * @param {import('@vueuse/core').WatchDebouncedOptions} [options]
     * @returns {import('vue').WatchStopHandle}
     */
    const watchLocationsFilter = (options = {}) => {
        return watchDebounced(
            dependencies.selectedLocations,
            (current, previous) => {
                if (!compare(current, previous)) {
                    console.log('[assign::dates::update] #locations', {
                        current,
                        previous,
                    });
                    debounced.onAssignDateRangeModifiers(
                        [
                            {
                                key: 'all',
                                value: properties.isAllModifierEnabled.value,
                            },
                            {
                                key: 'overlap',
                                value: properties.isOverlapModifierEnabled
                                    .value,
                            },
                        ],
                        true
                    );
                }
            },
            {
                debounce: 25,
                maxWait: 5000,
                flush: 'pre',
                deep: true,
                immediate: false,
                ...options,
            }
        );
    };

    /**
     * Define watcher that tracks weather station filter changes.
     * @param {import('@vueuse/core').WatchDebouncedOptions} [options]
     * @returns {import('vue').WatchStopHandle}
     */
    const watchWeatherStationsFilter = (options = {}) => {
        return watchDebounced(
            dependencies.selectedWeatherStations,
            (current, previous) => {
                if (!compare(current, previous)) {
                    console.log('[assign::dates::update] #stations', {
                        current,
                        previous,
                    });
                    debounced.onAssignDateRangeModifiers(
                        [
                            {
                                key: 'all',
                                value: properties.isAllModifierEnabled.value,
                            },
                            {
                                key: 'overlap',
                                value: properties.isOverlapModifierEnabled
                                    .value,
                            },
                        ],
                        true
                    );
                }
            },
            {
                debounce: 25,
                maxWait: 5000,
                flush: 'pre',
                deep: true,
                immediate: false,
                ...options,
            }
        );
    };

    return {
        watchAllDatesToggle,
        watchOverlappingDatesToggle,
        watchDateRangeInterval,
        watchDateRangeModifiers,
        watchLocationsFilter,
        watchWeatherStationsFilter,
    };
};

/**
 * Generate and return local state, properties, and methods for the Compare metrics page.
 * @param {Object} [props]
 * @param {Store<ECNBState>} [props.store] Optional {@link Store} instance.
 */
export const useDateRangeFilter = (props = {}) => {
    // <!-- DEFINE -->
    /** @type {Store<ECNBState>} */
    const store = props.store ?? useStore();
    /** @typedef {ReturnType<useState>} */
    const state = useState();
    /** @typedef {ReturnType<useConstants>} */
    const constants = useConstants();
    /** @typedef {ReturnType<useProperties>} */
    const properties = useProperties({ store, state, constants });
    /** @typedef {ReturnType<useMethods>} */
    const methods = useMethods({ store, state, constants, properties });
    /** @typedef {ReturnType<useWatchers>} */
    const watchers = useWatchers({
        store,
        state,
        constants,
        properties,
        methods,
    });
    // <!-- EXPOSE -->
    return {
        store,
        state,
        constants,
        properties,
        methods,
        watchers,
    };
};

// <!-- EXPORTS -->
export default {
    useDateRangeFilter,
};
